var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "h-mb-60" }, [
          _c("h1", { staticClass: "h1 h1--eliteTitles" }, [_vm._v("Services")]),
          _c("p", [_vm._v(_vm._s(_vm.elitepage.service_header_description))]),
        ]),
        _c(
          "div",
          { staticClass: "grid grid__col4 h-mb-80" },
          _vm._l(_vm.services, function (service) {
            return _c("PServiceBox", {
              key: service.id,
              staticClass: "h-marginAuto",
              attrs: { service: service },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }